import http from '@/services/http-client';
import { formatSuperAdminUrl as formatUrl } from '@/services/helpers/super-admin';
import routes from '@/../config/env';

const { charon } = routes;

export const createSportTemplate = (payload) => http({
  method: 'post',
  url: `${charon}/sport-templates`,
  data: payload,
});

export const cloneSportTemplate = (sportTemplateId) => http({
  method: 'post',
  url: `${charon}/sport-templates/${sportTemplateId}/clone`,
});

export const updateSportTemplate = (id, payload) => http({
  method: 'put',
  url: `${charon}/sport-templates/${id}`,
  data: payload,
});

export const updateSportTemplateCompetitions = ({ sportTemplateId, payload }) => http({
  method: 'post',
  url: `${charon}/sport-templates/${sportTemplateId}/update-for-competitions`,
  data: payload,
});

export const updateSportTemplateCompetition = ({ sportTemplateId, competitionId }) => http({
  method: 'post',
  url: `${charon}/sport-templates/${sportTemplateId}/set-for-competition?competitionId=${competitionId}`,
});

export const setSportTemplateAsDefault = (id) => http({
  method: 'post',
  url: `${charon}/sport-templates/${id}/set-default`,
});

export const deleteSportTemplate = (id) => http({
  method: 'delete',
  url: `${charon}/sport-templates/${id}`,
});

export const saveMarketConfiguration = (payload) => http({
  method: 'put',
  url: `${charon}/sport-templates/${payload.sportTemplateId}/update-partial`,
  data: payload,
});

export const suspendAllMarkets = (eventId) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/suspend-all`),
});

export const unsuspendAllMarkets = (eventId) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/unsuspend-all`),
});

export const suspendMarketType = ({ eventId, marketType }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/suspend-type`),
  data: marketType,
});

export const unsuspendMarketType = ({ eventId, marketType }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/unsuspend-type`),
  data: marketType,
});

export const suspendMarketTypeAllLines = ({ eventId, data }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/suspend-type/all-lines`),
  data,
});
export const unsuspendMarketTypeAllLines = ({ eventId, data }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/unsuspend-type/all-lines`),
  data,
});

export const batchSuspendAll = (eventIds) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/-/markets/batch-suspend-all`),
  data: { ids: eventIds },
});

export const batchUnsuspendAll = (eventIds) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/-/markets/batch-unsuspend-all`),
  data: { ids: eventIds },
});

export const toggleEventHighlight = ({ eventId, isHighlighted }) => http({
  method: isHighlighted ? 'post' : 'delete',
  url: `${charon}/events/${eventId}/highlight`,
});

export const manuallyResultMarkets = (eventId, payload) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/by-ids/result`),
  data: payload,
});

export const displayMarket = ({ eventId, marketId }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/${marketId}/display`),
});

export const undisplayMarket = ({ eventId, marketId }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/${marketId}/undisplay`),
});

export const updateMarketTier = ({ eventId, marketId, payload }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/${marketId}/override-limit`),
  data: payload,
});

export const batchUpdateMarkets = ({ eventId, action, marketIds }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/batch-${action}`),
  data: { ids: marketIds },
});

export const updateMarketBias = ({ eventId, marketId, payload }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/${marketId}/apply-bias`),
  data: payload,
});

export const overrideLimits = ({ eventId, type, tiers }) => http({
  method: 'post',
  url: `${charon}/events/${eventId}/override-limits`,
  data: { type, tiers },
});

export const refreshPrices = (eventId) => http({
  method: 'post',
  url: `${charon}/events/${eventId}/refresh-prices`,
});

export const updateMarketSuspensionByMarketId = ({
  eventId, marketId, operation, allLines,
}) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/${marketId}/${operation}${allLines ? '/all-lines' : ''}`),
});

export const batchUpdateMarketSuspensionByMarketId = ({ eventId, marketIds, operation }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/${operation}/all-lines`),
  data: { ids: marketIds },
});

export const displayAllEventMarkets = ({ id }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${id}/markets/display-all`),
  data: {},
});

export const undisplayAllEventMarkets = ({ id }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${id}/markets/undisplay-all`),
  data: {},
});

export const updateSportTemplateName = ({ id, name }) => http({
  method: 'post',
  url: `${charon}/sport-templates/${id}/update-name`,
  data: { name },
});

export const updateSportTemplateLimits = ({ id, payload }) => http({
  method: 'post',
  url: `${charon}/sport-templates/${id}/update-limits`,
  data: payload,
});

export const updateSportTemplateGameRisk = ({ id, riskConfigurations }) => http({
  method: 'post',
  url: `${charon}/sport-templates/${id}/game-risk`,
  data: riskConfigurations,
});

export const enableSportTemplateMarketConfigs = ({ id, payload }) => http({
  method: 'post',
  url: `${charon}/sport-templates/${id}/enable-market-configs`,
  data: payload,
});

export const disableSportTemplateMarketConfigs = ({ id, payload }) => http({
  method: 'post',
  url: `${charon}/sport-templates/${id}/disable-market-configs`,
  data: payload,
});

export const addSportTemplateMarketConfigs = ({ id, payload }) => http({
  method: 'post',
  url: `${charon}/sport-templates/${id}/add-market-configs`,
  data: payload,
});

export const removeSportTemplateMarketConfigs = ({ id, payload }) => http({
  method: 'delete',
  url: `${charon}/sport-templates/${id}/remove-market-configs`,
  data: payload,
});

// Manual edit
export const manuallyEditTeam = ({ sport, teamId, data }) => http({
  method: 'put',
  url: `${charon}/teams/${sport}/${teamId}`,
  data,
});

export const manuallyCreateTeam = ({ sport, data }) => http({
  method: 'post',
  url: `${charon}/teams/${sport}`,
  data,
});

export const manuallyRemoveTeam = ({ sport, teamId }) => http({
  method: 'delete',
  url: `${charon}/teams/${sport}/${teamId}`,
});

export const manuallyEditPlayer = ({ sport, playerId, data }) => http({
  method: 'put',
  url: `${charon}/players/${sport}/${playerId}`,
  data,
});

export const manuallyCreatePlayer = ({ sport, data }) => http({
  method: 'post',
  url: `${charon}/players/${sport}`,
  data,
});

export const manuallyRemovePlayer = ({ sport, playerId }) => http({
  method: 'delete',
  url: `${charon}/players/${sport}/${playerId}`,
});

export const manuallyUpdateSelectionConfig = (data) => http({
  method: 'put',
  url: `${charon}/display-configuration/selection-display`,
  data,
});

export const updateSportTemplateAssignedCompetitions = ({ sportTemplateId, idsToAssign, idsToUnassign }) => http({
  method: 'post',
  url: `${charon}/sport-templates/${sportTemplateId}/update-for-competitions`,
  data: {
    toSet: idsToAssign,
    toUnset: idsToUnassign,
  },
});

export const showAllMarketLines = ({ eventId, marketId }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/${marketId}/display/all-lines`),
});

export const hideAllMarketLines = ({ eventId, marketId }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/markets/${marketId}/undisplay/all-lines`),
});

export const manuallyUpdateMarketConfig = (data) => http({
  method: 'put',
  url: `${charon}/display-configuration/market-display`,
  data,
});

export const updateCompetitionFeedConfiguration = (feedConfigurations) => http({
  method: 'post',
  url: `${charon}/competition/feed-configuration`,
  data: {
    competitionFeedConfigurations: feedConfigurations,
  },
});
export const manuallyUpdateMarketGroup = (data) => http({
  method: 'put',
  url: `${charon}/display-configuration/market-group`,
  data,
});

export const manuallyRemoveMarketGroup = (marketGroupId) => http({
  method: 'delete',
  url: `${charon}/display-configuration/market-group/${marketGroupId}`,
});

export const updateMarketFavorites = ({ marketCode, sportId, action }) => http({
  method: action,
  url: `${charon}/market-favorites`,
  data: {
    marketCode,
    sportId,
  },
});

export const copyDefaultMarketDisplayConfiguration = ({ sportId, operatorIds }) => http({
  method: 'POST',
  url: `${charon}/display-configuration/market-display/set-to-default`,
  data: { sportId, operatorIds },
});

export const getMarketDisplayConfiguration = ({ sportLabel, operatorId }) => http({
  method: 'GET',
  url: `${charon}/display-configuration/market-display/all-content?sport=${sportLabel}&operatorId=${operatorId}`,
});

export const importMarketDisplayConfiguration = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return http({
    method: 'POST',
    url: `${charon}/display-configuration/market-display/all-content`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

export const changeEventPrimaryFeeds = ({ id, primaryFeeds }) => http({
  method: 'POST',
  url: formatUrl(`${charon}/events/${id}/change-primary-feeds`),
  data: { primaryFeeds },
});

export const setSportTemplateFeedSources = ({ id, feedSources }) => http({
  method: 'POST',
  url: `${charon}/sport-templates/${id}/feed-sources`,
  data: { feedSources },
});

export const createManualEvent = ({
  awayTeamId,
  competitionId,
  homeTeamId,
  startsAt,
}) => http({
  method: 'POST',
  url: `${charon}/events/create-event`,
  data: {
    awayTeamId,
    competitionId,
    homeTeamId,
    startsAt,
  },
});

export const propagateSportTemplate = (id) => http({
  method: 'POST',
  url: `${charon}/sport-templates/${id}/propagate`,
});

export const updateManualEvent = ({
  eventId,
  awayTeamId,
  competitionId,
  homeTeamId,
  startsAt,
}) => http({
  method: 'POST',
  url: `${charon}/events/update-event/${eventId}`,
  data: {
    awayTeamId,
    competitionId,
    homeTeamId,
    startsAt,
  },
});

export const approveEventPrices = (eventId) => http({
  method: 'POST',
  url: `${charon}/events/${eventId}/readiness`,
  data: {
    isApproved: true,
  },
});

export const setUserConfiguration = (configuration) => http({
  method: 'POST',
  url: `${charon}/user-config/odds-format/${configuration.oddsFormat}`,
});

export const updateSportTemplateSgpConfiguration = ({ id, sgpMarginConfiguration }) => http({
  method: 'post',
  url: `${charon}/sport-templates/${id}/update-sgp-margin`,
  data: sgpMarginConfiguration,
});

export const disableEvent = (eventId) => http({
  method: 'POST',
  url: `${charon}/events/disable-event/${eventId}`,
});

export const createNewCompetition = (data) => http({
  method: 'post',
  url: `${charon}/competition/create-competition`,
  data,
});

export const deleteCompetition = (id) => http({
  method: 'delete',
  url: `${charon}/competition/disable-competition/${id}`,
});

export const updateCompetition = (data) => http({
  method: 'post',
  url: `${charon}/competition/update-competition`,
  data,
});

export const updateMarketTemplateConfigs = ({ sportTemplateId, payload }) => http({
  method: 'POST',
  url: `${charon}/sport-templates/${sportTemplateId}/update-market-template-configs`,
  data: payload,
});

export const updateMarketResultingConfigs = ({ sportTemplateId, payload }) => http({
  method: 'POST',
  url: `${charon}/sport-templates/${sportTemplateId}/update-market-resulting-configs`,
  data: payload,
});

export const updateMarketMarginConfigs = ({ sportTemplateId, payload }) => http({
  method: 'POST',
  url: `${charon}/sport-templates/${sportTemplateId}/update-market-margin-configs`,
  data: payload,
});

export const updateMarketLimitConfigs = ({ sportTemplateId, payload }) => http({
  method: 'POST',
  url: `${charon}/sport-templates/${sportTemplateId}/update-market-limit-configs`,
  data: payload,
});

export const resetMarketBias = ({ eventId }) => http({
  method: 'post',
  url: formatUrl(`${charon}/events/${eventId}/reset-bias`),
});
