import apolloClient, { BET_TICKER_CLIENT_NAME as clientName } from '@/services/apollo-client';
import allClientsQuery from './allClients.query.gql';
import allBetsQuery from './allBets.query.gql';
import allDynamicBetsQuery from './allDynamicBets.query.gql';
import allBetTickersQuery from './allBetTickers.query.gql';
import allLiabilityEventsQuery from './allLiabilityEvents.query.gql';
import liabilityEventQuery from './liabilityEvent.query.gql';
import allCustomersTotalCountQuery from './allCustomersTotalCount.query.gql';
import allCustomersQuery from './allCustomers.query.gql';
import customerQuery from './customer.query.gql';
import customerCommentsQuery from './customerComments.query.gql';
import customerRepliesQuery from './customerReplies.query.gql';

export const useAllClientsQuery = () => apolloClient
  .query({
    query: allClientsQuery,
    context: { clientName },
    fetchPolicy: 'network-only',
  })
  .then((response) => response)
  .catch((err) => err);

export const useAllBetsQuery = (options) => apolloClient
  .query({
    query: allBetsQuery,
    variables: options,
    context: {
      clientName,
      requestTrackerId: 'useAllBetsQuery',
    },
    fetchPolicy: 'network-only',
  })
  .then((response) => response)
  .catch((err) => err);

export const useAllDynamicBetsQuery = (options) => apolloClient
  .query({
    query: allDynamicBetsQuery,
    variables: options,
    context: {
      clientName,
      requestTrackerId: 'useAllDynamicBetsQuery',
    },
    fetchPolicy: 'network-only',
  })
  .then((response) => response)
  .catch((err) => err);

export const useAllBetTickersQuery = () => apolloClient
  .query({
    query: allBetTickersQuery,
    context: { clientName },
    fetchPolicy: 'network-only',
  })
  .then((response) => response)
  .catch((err) => err);

export const useAllLiabilityEventsQuery = (options) => apolloClient
  .query({
    query: allLiabilityEventsQuery,
    variables: options,
    context: {
      clientName,
      requestTrackerId: 'useAllLiabilityEventsQuery',
    },
    fetchPolicy: 'network-only',
  })
  .then((response) => response)
  .catch((err) => err);

export const useLiabilityEventQuery = (options) => apolloClient
  .query({
    query: liabilityEventQuery,
    variables: options,
    context: {
      clientName,
      requestTrackerId: 'liabilityEventQuery',
    },
    fetchPolicy: 'network-only',
  })
  .then((response) => response)
  .catch((err) => err);

export const useAllCustomersTotalCountQuery = (options) => apolloClient
  .query({
    query: allCustomersTotalCountQuery,
    variables: options,
    context: {
      clientName,
      requestTrackerId: 'useAllCustomersTotalCountQuery',
    },
    fetchPolicy: 'network-only',
  });

export const useAllCustomersQuery = (options) => apolloClient
  .query({
    query: allCustomersQuery,
    variables: options,
    context: {
      clientName,
      requestTrackerId: 'useAllCustomersQuery',
    },
    fetchPolicy: 'network-only',
  });

export const useCustomerQuery = (options) => apolloClient
  .query({
    query: customerQuery,
    variables: options,
    context: {
      clientName,
      requestTrackerId: 'useCustomersQuery',
    },
    fetchPolicy: 'network-only',
  });

export const useCustomerCommentsQuery = (options) => apolloClient
  .query({
    query: customerCommentsQuery,
    variables: options,
    context: {
      clientName,
      requestTrackerId: 'useCustomerCommentsQuery',
    },
    fetchPolicy: 'network-only',
  });

export const useCustomerRepliesQuery = (options) => apolloClient
  .query({
    query: customerRepliesQuery,
    variables: options,
    context: {
      clientName,
      requestTrackerId: 'useCustomerRepliesQuery',
    },
    fetchPolicy: 'network-only',
  });
