import { pickBy, identity } from 'lodash';
import http from '@/services/http-client';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import routes from '@/../config/env';

const { accountInfo } = routes;

const formatUrl = (url) => {
  const { isSuperAdminSelected } = getSuperAdminData();
  if (isSuperAdminSelected) return `${accountInfo}/admin/${url}`;
  return `${accountInfo}/${url}`;
};

const formatData = (data) => {
  const { isSuperAdminSelected } = getSuperAdminData();
  if (isSuperAdminSelected) return pickBy(data, identity);
  const { operatorId, ...newData } = data;
  return pickBy(newData, identity);
};

export const flagCustomerProfile = ({ accountId, operatorId }) => http({
  method: 'post',
  url: formatUrl('account/flag'),
  data: formatData({
    accountId,
    operatorId,
  }),
});

export const unflagCustomerProfile = ({ accountId, operatorId }) => http({
  method: 'post',
  url: formatUrl('account/unflag'),
  data: formatData({
    accountId,
    operatorId,
  }),
});

export const setCustomerProfileFlag = ({ accountId, operatorId, isFlagged }) => {
  if (isFlagged) return flagCustomerProfile({ accountId, operatorId });
  return unflagCustomerProfile({ accountId, operatorId });
};

export const setCustomerProfileStrength = ({ accountId, operatorId, strength }) => http({
  method: 'post',
  url: formatUrl('account/set-strength'),
  data: formatData({
    accountId,
    operatorId,
    strength,
  }),
});

export const setCustomerProfileNickname = ({ accountId, operatorId, nickname }) => http({
  method: 'post',
  url: formatUrl('account/set-nickname'),
  data: formatData({
    accountId,
    operatorId,
    nickname,
  }),
});

export const addComment = ({ accountId, operatorId, content }) => http({
  method: 'post',
  url: formatUrl('comment/add'),
  data: formatData({
    accountId,
    operatorId,
    content,
  }),
});

export const editComment = ({ operatorId, commentId, content }) => http({
  method: 'put',
  url: formatUrl('comment'),
  data: formatData({
    operatorId,
    commentId,
    newContent: content,
  }),
});

export const deleteComment = ({ operatorId, commentId }) => http({
  method: 'delete',
  url: formatUrl(`comment/${commentId}`),
  data: formatData({
    operatorId,
  }),
});

export const addThread = ({ operatorId, commentId, content }) => http({
  method: 'post',
  url: formatUrl('thread'),
  data: formatData({
    operatorId,
    parentCommentId: commentId,
    content,
  }),
});

export const editThread = ({ operatorId, threadId, content }) => http({
  method: 'put',
  url: formatUrl('thread'),
  data: formatData({
    operatorId,
    threadId,
    newContent: content,
  }),
});

export const deleteThread = ({ operatorId, threadId }) => http({
  method: 'delete',
  url: formatUrl(`thread/${threadId}`),
  data: formatData({
    operatorId,
  }),
});

export const recalculateCustomerKpis = ({ accountId, operatorId }) => http({
  method: 'post',
  url: formatUrl('account/recalculate-kpis'),
  data: formatData({
    accountId,
    operatorId,
  }),
});
